import React from 'react'
import Helmet from 'react-helmet'
import SectionHero from '../components/SectionHero.js'
import SectionClientBanners from '../components/SectionClientBanners.js'
import SectionKeysSuccess from '../components/SectionKeysSuccess.js'
import SectionWorkLifeBalance from '../components/SectionWorkLifeBalance.js'
import SectionPeopleAnalytics from '../components/SectionPeopleAnalytics.js'
import SectionGoalsHabits from '../components/SectionGoalsHabits.js'
import SectionReviews from '../components/SectionReviews.js'
import Layout from '../layouts/sv'

const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet title="Remente - Engagement 3.0, Målsättning & Medarbetarinsikter">
        <meta
          name="description"
          content="Låt den ledande plattformen för personlig utveckling få din organisation att växa."
        />
      </Helmet>
      <SectionHero />
      <SectionClientBanners />
      <SectionKeysSuccess />
      <SectionWorkLifeBalance />
      <SectionPeopleAnalytics />
      <SectionGoalsHabits />
      <SectionReviews />
    </Layout>
  )
}

export default IndexPage
